import * as React from "react"
import styled, { css } from "styled-components"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"


import { COLOR_MAIN, COLOR_WHITE } from "style/colors"
import { CONTENT_SIZE } from "style/size"

import { Layout } from "components/Layout"

import { Title as BaseTitle } from "components/text/Title"
import { Caption } from "components/text/Caption"

import { Carousel } from "components/interactable/Carousel"
import { ContentPortableText } from "components/blockContent/ContentPortableText"


const Image = styled(Img)`
	/*flex: 1;*/
`

const Block = styled.div`
	padding: 0 24px;
	margin: 0 auto;
	margin-bottom: ${props => (props.noMargin ? "0" : "40px")};
	max-width: ${CONTENT_SIZE}px;
	width: 100%;

	display: grid;
	flex-direction: column;
	align-items: stretch;
`
const BreadcrumbContainer = styled.div`
	background-color: ${COLOR_MAIN};
	color: ${COLOR_WHITE};
	width: 100%;
	text-align: center;
	padding-bottom: 1em;
`
const StyledLink = styled(Link)`
	color: ${COLOR_WHITE};
`
const Heading = styled.div`
	${props =>
		props.hasBackground &&
		css`
			background-color: ${COLOR_MAIN};
		`}
`

const Title = styled(BaseTitle)`
	padding: 24px 24px;
	max-width: 1146px;
	margin: auto;
	text-align: center;
`

const Item = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`

const HeaderContainer = styled.div`
	position: relative;
`
const Spacing = styled.span`
	padding-left: .1em;
`

const TriangleContainer = styled.div`
	background-color: ${COLOR_MAIN};
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 50px;
	z-index: -1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: stretch;
`

const Triangle = styled.div`
	position: relative;
	top: 1px;
	display: block;
	width: 100%;
	height: 100px;
	background-color: ${COLOR_WHITE};
	/*clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 40%);*/
	clip-path: polygon(0 0, 100% 100%, 0 100%);
	@media screen and (min-width: 800px) {
		height: 200px;
	}
`

const Background = () => (
	<TriangleContainer>
		<Triangle />
	</TriangleContainer>
)

const HeaderContent = ({ gallery }) => {
	if (!gallery) return undefined

	if (gallery.length === 1) {
		const {
			alt,
			//caption,
			image,
		} = gallery?.[0] || {}
		return (
			<HeaderContainer>
				<Block noMargin>
					<Image fluid={image?.asset?.fluid} alt={alt} />
				</Block>
				<Background />
			</HeaderContainer>
		)
	}



/* 	const currentPage = window.location.pathname;
 */
	return (
		<HeaderContainer>
			<Block noMargin>
				<Carousel>
					{gallery?.map(({ _key, alt, caption, image }) => {
						return (
							<Item key={_key}>
								<Image fluid={image?.asset?.fluid} alt={alt} />
								<Caption>{caption}</Caption>
							</Item>
						)
					})}
				</Carousel>
			</Block>
			<Background />
		</HeaderContainer>
	)
}

const TemplatePage = ({ data }) => {
	console.log({ data })
	const {
		_rawContent,
		title,
		meta,
		slug: { current },
		gallery,
	} = data?.sanityPage || {}

	console.log({ title, current })

	return (
		<Layout meta={meta}>
 			<BreadcrumbContainer>
				<StyledLink to="/" >Hjem</StyledLink> <Spacing/> / <Spacing>{title}</Spacing>
			</BreadcrumbContainer> 
			<Heading>
				{/* hasBackground={headerType != "TITLE" ? true : false}*/}
				<HeaderContent gallery={gallery} />
				<Title>{title}</Title>
				{/*<Breadcrumb crumbs={breadcrumbs} crumbSeparator=" - " crumbLabel="Home" /> */}
			</Heading>
			<Block>
				<ContentPortableText blocks={_rawContent} />
			</Block>
		</Layout>
	)
}

export const query = graphql`
	query GetPageContent($id: String) {
		sanityPage(id: { eq: $id }) {
			title
			slug {
				current
			}
			meta {
				title
				description
			}
			gallery {
				_key
				alt
				caption
				image {
					asset {
						fluid(maxWidth: 700) {
							src
							srcWebp
							srcSetWebp
							srcSet
							sizes
							base64
							aspectRatio
						}
					}
				}
			}
			_rawContent(resolveReferences: { maxDepth: 10 })
		}
	}
`

export default TemplatePage
